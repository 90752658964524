<template>
	<div>
		<div class="search_box">
			<el-date-picker
				v-model="date"
				type="datetimerange"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
			></el-date-picker>
			<el-button
				type="success"
				class="search_button"
				@click="searchSubmit"
				size="mini"
			>
				搜索
			</el-button>
		</div>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>报修数量</span>

						<el-button
							size="mini"
							round
							@click="
								downloadExcel({
									index: 3,
									title: '报修数量',
									options: options3
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(3)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts3" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>修复数量</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcel({
									index: 1,
									title: '修复数量',
									options: options1
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(1)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts1" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>报修应收</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcel({
									index: 4,
									title: '报修应收',
									options: options4
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(4)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts4" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>报修实收</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcel({
									index: 8,
									title: '报修实收',
									options: options8
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(8)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts8" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>修复费用</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcel({
									index: 2,
									title: '修复费用',
									options: options2
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(2)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts2" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>签单数量</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcel({
									index: 5,
									title: '签单数量',
									options: options5
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(5)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts5" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>签单费用</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcel({
									index: 6,
									title: '签单费用',
									options: options6
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(6)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts6" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>报修成本</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcel({
									index: 7,
									title: '报修成本',
									options: options7
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(7)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts7" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<Dialog
			:title="dialogTitle"
			:visible.sync="isShowBigEcharts"
			width="90%"
			@before-close="beforeCloseHandler"
		>
			<div v-if="isShowBigEcharts">
				<div id="bigEchartsBox" class="bigEchartsBox"></div>
			</div>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getCompRepairCntService } from '@s/hospital/CompsummaryService';
import Dialog from '@c/ui/Dialog';
import {
	htimeFormat,
	hgetStorage,
	hdateInMonth,
	hmonthRange
} from '@u/htools.web';

const posList = [
	'left',
	'right',
	'top',
	'bottom',
	'inside',
	'insideTop',
	'insideLeft',
	'insideRight',
	'insideBottom',
	'insideTopLeft',
	'insideTopRight',
	'insideBottomLeft',
	'insideBottomRight'
];
app.configParameters = {
	rotate: {
		min: -90,
		max: 90
	},
	align: {
		options: {
			left: 'left',
			center: 'center',
			right: 'right'
		}
	},
	verticalAlign: {
		options: {
			top: 'top',
			middle: 'middle',
			bottom: 'bottom'
		}
	},
	position: {
		options: posList.reduce((map, pos) => {
			map[pos] = pos;
			return map;
		}, {})
	},
	distance: {
		min: 0,
		max: 100
	}
};
app.config = {
	rotate: 60,
	align: 'left',
	verticalAlign: 'middle',
	position: 'insideBottom',
	distance: 15,
	onChange: () => {
		const labelOption = {
			rotate: app.config.rotate,
			align: app.config.align,
			verticalAlign: app.config.verticalAlign,
			position: app.config.position,
			distance: app.config.distance
		};
		myChart.setOption({
			series: [
				{
					label: labelOption
				},
				{
					label: labelOption
				},
				{
					label: labelOption
				},
				{
					label: labelOption
				}
			]
		});
	}
};
const labelOption = {
	show: true,
	position: app.config.position,
	distance: app.config.distance,
	align: app.config.align,
	verticalAlign: app.config.verticalAlign,
	rotate: app.config.rotate,
	// formatter: '{c}  {name|{a}}',
	formatter: '{a}',
	fontSize: 14,
	color: 'black',
	rich: {
		name: {}
	}
};
export default {
	components: {
		Dialog
	},
	data() {
		return {
			date: '',
			dateType: 2, // 月
			dateEndType: 2, // 月
			dialogTitle: '',
			options1: {
				// title: {
				// 	text: '修复数量'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						const count = arg.reduce((prev, cur) => {
							return prev + cur.value;
						}, 0);

						let str0 = '<div class="ownTooltip">';
						arg.forEach(item => {
							str0 += `<span class="itemWrap"><span class="key">${item.marker}${item.seriesName}:</span> <span class="value">${item.data}</span></span><br/>`;
						});
						return `${
							arg[0].axisValueLabel
						}<br/>${str0}<span style="margin-left:10px;">合计：</sapn><b style="color:red">${count.toFixed(
							2
						)}</b></div>`;
					}
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			options2: {
				// title: {
				// 	text: '修复费用'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						const count = arg.reduce((prev, cur) => {
							return prev + cur.value;
						}, 0);

						let str0 = '<div class="ownTooltip">';
						arg.forEach(item => {
							str0 += `<span class="itemWrap"><span class="key">${item.marker}${item.seriesName}:</span> <span class="value">${item.data}</span></span><br/>`;
						});
						return `${
							arg[0].axisValueLabel
						}<br/>${str0}<span style="margin-left:10px;">合计：</sapn><b style="color:red">${count.toFixed(
							2
						)}</b></div>`;
					}
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			options3: {
				title: {
					// text: '报修数量'
				},
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						const count = arg.reduce((prev, cur) => {
							return prev + cur.value;
						}, 0);

						let str0 = '<div class="ownTooltip">';
						arg.forEach(item => {
							str0 += `<span class="itemWrap"><span class="key">${item.marker}${item.seriesName}:</span> <span class="value">${item.data}</span></span><br/>`;
						});
						return `${
							arg[0].axisValueLabel
						}<br/>${str0}<span style="margin-left:10px;">合计：</sapn><b style="color:red">${count.toFixed(
							2
						)}</b></div>`;
					}
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			options4: {
				// title: {
				// 	text: '报修应收'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						const count = arg.reduce((prev, cur) => {
							return prev + cur.value;
						}, 0);

						let str0 = '<div class="ownTooltip">';
						arg.forEach(item => {
							str0 += `<span class="itemWrap"><span class="key">${item.marker}${item.seriesName}:</span> <span class="value">${item.data}</span></span><br/>`;
						});
						return `${
							arg[0].axisValueLabel
						}<br/>${str0}<span style="margin-left:10px;">合计：</sapn><b style="color:red">${count.toFixed(
							2
						)}</b></div>`;
					}
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			options8: {
				// title: {
				// 	text: '报修实收'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						const count = arg.reduce((prev, cur) => {
							return prev + cur.value;
						}, 0);

						let str0 = '<div class="ownTooltip">';
						arg.forEach(item => {
							str0 += `<span class="itemWrap"><span class="key">${item.marker}${item.seriesName}:</span> <span class="value">${item.data}</span></span><br/>`;
						});
						return `${
							arg[0].axisValueLabel
						}<br/>${str0}<span style="margin-left:10px;">合计：</sapn><b style="color:red">${count.toFixed(
							2
						)}</b></div>`;
					}
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			options7: {
				// title: {
				// 	text: '报修成本'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						const count = arg.reduce((prev, cur) => {
							return prev + cur.value;
						}, 0);

						let str0 = '<div class="ownTooltip">';
						arg.forEach(item => {
							str0 += `<span class="itemWrap"><span class="key">${item.marker}${item.seriesName}:</span> <span class="value">${item.data}</span></span><br/>`;
						});
						return `${
							arg[0].axisValueLabel
						}<br/>${str0}<span style="margin-left:10px;">合计：</sapn><b style="color:red">${count.toFixed(
							2
						)}</b></div>`;
					}
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			options5: {
				// title: {
				// 	text: '报修应收'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						const count = arg.reduce((prev, cur) => {
							return prev + cur.value;
						}, 0);

						let str0 = '<div class="ownTooltip">';
						arg.forEach(item => {
							str0 += `<span class="itemWrap"><span class="key">${item.marker}${item.seriesName}:</span> <span class="value">${item.data}</span></span><br/>`;
						});
						return `${
							arg[0].axisValueLabel
						}<br/>${str0}<span style="margin-left:10px;">合计：</sapn><b style="color:red">${count.toFixed(
							2
						)}</b></div>`;
					}
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			options6: {
				// title: {
				// 	text: '签单费用'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						const count = arg.reduce((prev, cur) => {
							return prev + cur.value;
						}, 0);

						let str0 = '<div class="ownTooltip">';
						arg.forEach(item => {
							str0 += `<span class="itemWrap"><span class="key">${item.marker}${item.seriesName}:</span> <span class="value">${item.data}</span></span><br/>`;
						});
						return `${
							arg[0].axisValueLabel
						}<br/>${str0}<span style="margin-left:10px;">合计：</sapn><b style="color:red">${count.toFixed(
							2
						)}</b></div>`;
					}
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			isShowBigEcharts: false
		};
	},
	mounted() {
		const myDate = new Date();
		const curMonth = myDate.getMonth() + 1;
		const curYear = myDate.getFullYear();
		const curYM = `${curYear}-${curMonth}`;
		const prevMonthes = hmonthRange(curYM, 7, 1);
		prevMonthes.shift();
		prevMonthes.push(curYM);
		const beginMonth = prevMonthes[1];
		let beginTime = `${beginMonth}-01 00:00:00`;
		// const endTime = curYM + "-" + hdateInMonth(beginMonth) + " 23:59:59";
		let endTime = htimeFormat(
			new Date().getTime(),
			`{y}-${curMonth + 1}-01 23:59:59`
		);
		endTime = new Date(endTime).getTime() - 1000 * 60 * 60 * 24;
		endTime = htimeFormat(endTime, '{y}-{m}-{d} 23:59:59');
		// 上面的没用
		beginTime = this.$moment(new Date())
			.subtract(5, 'months')
			.format('YYYY-MM-01 00:00:00');
		endTime = this.$moment()
			.endOf('months')
			.format('YYYY-MM-DD HH:mm:ss');
		this.date = [`${beginTime}`, `${endTime}`];
		this.compRepairCntService();
	},
	methods: {
		beforeCloseHandler() {
			this.isShowBigEcharts = false;
		},
		showBigEchartsHandler(index) {
			this.dialogTitle = '';
			this.isShowBigEcharts = true;
			setTimeout(() => {
				const chartDom = document.getElementById('bigEchartsBox');
				this.myChart = this.$echarts.init(chartDom);
				// if (index === 1) {
				// 	this.options1.series.forEach(item => {
				// 		item.label = labelOption;
				// 	});
				// 	this.myChart.setOption(this.options1);
				// }
				// if (index === 2) {
				// 	this.options2.series.forEach(item => {
				// 		item.label = labelOption;
				// 	});
				// 	this.myChart.setOption(this.options2);
				// }
				if (index === 1) {
					this.dialogTitle = '修复数量';
				}
				if (index === 2) {
					this.dialogTitle = '修复费用';
				}
				if (index === 3) {
					this.dialogTitle = '报修数量';
				}
				if (index === 4) {
					this.dialogTitle = '报修应收';
				}
				if (index === 5) {
					this.dialogTitle = '签单数量';
				}
				if (index === 6) {
					this.dialogTitle = '签单费用';
				}
				if (index === 7) {
					this.dialogTitle = '报修成本';
				}
				if (index === 8) {
					this.dialogTitle = '报修实收';
				}
				// if (index === 4) {
				// 	this.options4.series.forEach(item => {
				// 		item.label = labelOption;
				// 	});
				// 	this.myChart.setOption(this.options4);
				// }
				// if (index === 5) {
				// 	this.options5.series.forEach(item => {
				// 		item.label = labelOption;
				// 	});
				// 	this.myChart.setOption(this.options5);
				// }
				// if (index === 6) {
				// 	this.options6.series.forEach(item => {
				// 		item.label = labelOption;
				// 	});
				// 	this.myChart.setOption(this.options6);
				// }
				this[`options${index}`].series.forEach(item => {
					item.label = labelOption;
				});
				this.myChart.setOption(this[`options${index}`]);
				// this.myChart.on('magictypechanged', params => {
				// 	console.log('this.myChart');
				// 	console.log(params);
				// this.myChart.setOption(this['options' + index]);
				// });
			}, 500);
		},
		dateSwitch(dateType) {
			this.dateType = dateType;
			this.allHospitalRepairList(this.dateType);
		},
		dateEndSwitch(dateType) {
			this.dateEndType = dateType;
			this.repairDoneCntPriceByMonthList(this.dateEndType);
		},
		searchSubmit() {
			this.compRepairCntService();
		},
		// 1、处理横坐标 时间
		// 2、去重
		// 3、排序
		detaEchartsTime(res) {
			console.log('res ygAdmin 维修统计');
			console.log(res);
		},
		// ygAdmin 维修统计
		async compRepairCntService() {
			// b: "2022-02-01" 月
			// donecnt: 0 修复量
			// doneprice: 0 修复价格
			// e: "2022-02-28T16:00:00.000+00:00"
			// hosid: 1117
			// hosname: "襄汾医院"
			// mth: "2022-02"
			// repaircnt: 0 报修量
			// repairprice: 0 报修价格
			// 横坐标 时间
			let dateArr = [];
			let legendArrTemp = [];
			const legendArr = [];
			// 纵坐标 3、	修复数量
			const donecntArr = [];
			// 纵坐标 4、	修复费用
			const donepriceArr = [];
			// 纵坐标 1、	报修数量
			const repaircntArr = [];
			// 纵坐标 2、	报修应收
			const repairpriceArr = [];
			// 纵坐标 8、	报修实收
			const repairrealpriceArr = [];
			// 纵坐标 7、	报修成本
			const costArr = [];
			// 纵坐标 5、	签单数量
			const signcntArr = [];
			// 纵坐标 5、	签单费用
			const signpriceArr = [];

			const dataJson = {
				begin: htimeFormat(this.date[0]),
				end: htimeFormat(this.date[1])
			};
			const res = await getCompRepairCntService(dataJson);
			this.tableDownData = res;
			// 1、处理横坐标 时间
			// 2、去重
			// 3、排序
			// 4、获取医院列表
			// 5、医院列表去重
			// 6、医院item下的数组
			res.forEach(item => {
				dateArr.push(item.mth);
				legendArrTemp.push(item.hosname);
				item.mthTime = new Date(item.mth).getTime();
			});
			res.sort((a, b) => {
				return a.mthTime > b.mthTime ? 1 : -1;
			});
			dateArr.sort((a, b) => {
				return a > b ? 1 : -1;
			});
			dateArr = [...new Set(dateArr)];
			legendArrTemp = [...new Set(legendArrTemp)];
			legendArrTemp.forEach(item => {
				legendArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				donecntArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				donepriceArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				costArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				repaircntArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				repairpriceArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				repairrealpriceArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				signcntArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				signpriceArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
			});
			for (let index = 0; index < legendArr.length; index++) {
				res.forEach(item1 => {
					if (legendArr[index].name == item1.hosname) {
						donecntArr[index].data.push(item1.donecnt);
						donepriceArr[index].data.push(item1.doneprice);
						repaircntArr[index].data.push(item1.repaircnt);
						costArr[index].data.push(item1.repaircost);
						repairpriceArr[index].data.push(item1.repairprice);
						repairrealpriceArr[index].data.push(item1.realprice);
						signcntArr[index].data.push(item1.signcnt);
						signpriceArr[index].data.push(item1.signprice);
					}
				});
			}
			res.forEach(item => {
				dateArr.push(item.mth);
				legendArrTemp.push(item.hosname);
			});

			this.echartsInit1({ xAxis: dateArr, series: donecntArr });
			this.echartsInit2({ xAxis: dateArr, series: donepriceArr });
			this.echartsInit3({ xAxis: dateArr, series: repaircntArr });
			this.echartsInit4({ xAxis: dateArr, series: repairpriceArr });
			this.echartsInit8({ xAxis: dateArr, series: repairrealpriceArr });
			this.echartsInit5({ xAxis: dateArr, series: signcntArr });
			this.echartsInit6({ xAxis: dateArr, series: signpriceArr });
			this.echartsInit7({ xAxis: dateArr, series: costArr });
		},
		echartsInit1(res) {
			this.options1.xAxis[0].data = [...new Set(res.xAxis)];
			this.options1.series = res.series;
			const chartDom = document.getElementById('echarts1');
			this.$echarts.init(document.getElementById('echarts1')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options1);
		},
		echartsInit2(res) {
			this.options2.xAxis[0].data = [...new Set(res.xAxis)];
			this.options2.series = res.series;
			const chartDom = document.getElementById('echarts2');
			this.$echarts.init(document.getElementById('echarts2')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options2);
		},
		echartsInit3(res) {
			this.options3.xAxis[0].data = [...new Set(res.xAxis)];
			this.options3.series = res.series;
			const chartDom = document.getElementById('echarts3');
			this.$echarts.init(document.getElementById('echarts3')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options3);
		},
		echartsInit4(res) {
			this.options4.xAxis[0].data = [...new Set(res.xAxis)];
			this.options4.series = res.series;
			const chartDom = document.getElementById('echarts4');
			this.$echarts.init(document.getElementById('echarts4')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options4);
		},
		// 报修实收
		echartsInit8(res) {
			this.options8.xAxis[0].data = [...new Set(res.xAxis)];
			this.options8.series = res.series;
			const chartDom = document.getElementById('echarts8');
			this.$echarts.init(document.getElementById('echarts8')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options8);
		},
		echartsInit7(res) {
			this.options7.xAxis[0].data = [...new Set(res.xAxis)];
			this.options7.series = res.series;
			const chartDom = document.getElementById('echarts7');
			this.$echarts.init(document.getElementById('echarts7')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options7);
		},
		echartsInit5(res) {
			this.options5.xAxis[0].data = [...new Set(res.xAxis)];
			this.options5.series = res.series;
			const chartDom = document.getElementById('echarts5');
			this.$echarts.init(document.getElementById('echarts5')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options5);
		},
		echartsInit6(res) {
			this.options6.xAxis[0].data = [...new Set(res.xAxis)];
			this.options6.series = res.series;
			const chartDom = document.getElementById('echarts6');
			this.$echarts.init(document.getElementById('echarts6')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options6);
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},
		downloadExcel(obj) {
			const tHeader = [
				'医院',
				'日期',
				'报修数量',
				'修复数量',
				'报修应收',
				'报修实收',
				'修复费用',
				'签单数量',
				'签单费用',
				'报修成本'
			];
			const filterVal = [
				'hosname',
				'mth',
				'repaircnt',
				'donecnt',
				'repairprice',
				'realprice',
				'doneprice',
				'signcnt',
				'signprice',
				'repaircost'
			];
			const data = this.formatJson(filterVal, this.tableDownData);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `维修统计${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		downloadExcel1(obj) {
			const { index, options, title } = obj;
			console.log(index);
			console.log(options);
			const { type } = options.series[0];

			let tHeader = [];
			const data = [];
			if (type === 'bar' || type === 'line') {
				tHeader = options.xAxis[0].data;
				options.series.forEach(item => {
					data.push(item.data);
				});
			}

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `${title}${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		}
	}
};
</script>

<style scoped>
.el-col {
	margin-bottom: 20px;
}

.date_tag {
	position: absolute;
	top: 96px;
	right: 13px;
	height: 30px;
}

.activeDate {
	color: #409eff;
	border-color: #c6e2ff;
	background-color: #ecf5ff;
}

.search_box {
	margin-bottom: 20px;
}

.echart_item {
	width: 500px !important;
	height: 360px;
	margin: 0 auto;
}

.echart_box {
	width: 460px;
	height: 360px;
}

.search_button {
	margin-left: 10px;
}

.itemWrap {
	display: flex;
	justify-content: space-between;
}

.itemWrap .value {
	margin-left: 30px;
}
.clearfix {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.clearfix > span {
	flex: 1;
}

.bigEchartsBox {
	width: 100%;
	height: calc(100vh - 160px);
}
</style>
